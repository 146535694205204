import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WSResponse } from '@shared/entities/DTO/wsResponse.dto';

@Injectable({
  providedIn: 'root'
})
export class ProdutoVitrineService {

  constructor(
    private http: HttpClient
  ) { }

  public async listByCategoryName(category: string): Promise<WSResponse> {
    return this.http.get<WSResponse>(`/showcase/product/category/${category}/list`).toPromise(); 
  }
}
