import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextFormatService {

  constructor() { }

  substractAndReplace(text: string, ammount: number, replace: string){
    if(ammount >= 1){
      if(text.length >= ammount){
        return text.substr(0,ammount)+replace;
      }else{
        return text;
      }
    }else{
      return text+replace;
    }
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import CartItem from '@shared/entities/cartItem.entity';
import Offer from '@shared/entities/offer.entity';
import OfferRule from '@shared/entities/offerRule.entity';
import PriceTable from '@shared/entities/priceTable.entity';
import Product from '@shared/entities/product.entity';
@Pipe({name: 'limitTextLength'})
export class LimitTextLength implements PipeTransform {
  transform(value: string, ammount: number): string {

    if(ammount >= 1){
      if(value.length >= ammount){
        return value.substr(0,ammount)+'...';
      }else{
        return value;
      }
    }else{
      return value+'...';
    }

  }
}

@Pipe({ name: 'productPrice' })
export class ProductPrice implements PipeTransform {

  transform(product: Product, offer?: Offer, simple?: boolean) {
    
    let priceTable = product.prices && product.prices.length > 0 && product.prices[0] ? product.prices[0].priceTable : null;
    let price = product.prices && product.prices.length > 0 && product.prices[0] ? product.prices[0].price : 0;
    let currency = priceTable && priceTable.currency ? priceTable.currency.alias : 'BRL';

    if (offer) {
      offer = Object.assign(new Offer(), offer);
      price = offer.offerDiscountValue(price);
    }

    let new_value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency }).format(price);
    new_value.replace('.', '|').replace(',', '.').replace('|', ',');

    if (!simple) {
      return `${new_value}`;
    }

    return `<b>${new_value}</b>`;
  }
  
}

@Pipe({ name: 'productDiscount' })
export class ProductDiscount implements PipeTransform {

  transform(product: Product, offer?: Offer, cartItem?: CartItem) {

    let priceTable = product.prices && product.prices.length > 0 && product.prices[0] ? product.prices[0].priceTable : null;
    let price = product.prices && product.prices.length > 0 && product.prices[0] ? product.prices[0].price : 0;
    let currency = priceTable && priceTable.currency ? priceTable.currency.alias : 'BRL';
    let priceFull = price;

    if (offer) {
      offer = Object.assign(new Offer(), offer);
      price = (offer.offerDiscountPrice(price) * (cartItem && cartItem.amount > 0 ? Number(cartItem.amount) : 1));
    }


    /*
          Validar!!! Desconto é sobre o valor final referente à qtd no carrinho ou individual??
          Ex: 
          10,00 * 2 = 20   ->    10,00 ( - 2,50 desconto) = 7,50 * 2 = 15,00
          ** Diferente que: **
          10 * 2 = 20,00 - 2,50 = > 17,50
    */
    price = price / Number(cartItem.amount);

    
    let new_value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency }).format(price);
    new_value.replace('.', '|').replace(',', '.').replace('|', ',');

    let new_value2 = new Intl.NumberFormat('pt-BR', { style: 'currency', currency }).format(priceFull - price);
    new_value2.replace('.', '|').replace(',', '.').replace('|', ',');

    return `Com desconto: <b>${new_value2}</b> <b class="pricesuccess">( - ${new_value} )</b> ~ ${offer.offerName}`;
    //return `<b class="pricesuccess">- ${new_value}</b> ~ ${offer.offerName}`;
  }

}

@Pipe({ name: 'cartItemPrice' })
export class CartItemPrice implements PipeTransform {

  transform(cartItem: CartItem) {
    cartItem = Object.assign(new CartItem(), cartItem);

    let priceTable: PriceTable = cartItem?.price?.priceTable;
    let currency = priceTable && priceTable.currency ? priceTable.currency.alias : 'BRL';

    let new_value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency }).format(cartItem.productUnitPrice);
    new_value.replace('.', '|').replace(',', '.').replace('|', ',');
    return `${new_value}`;
  }

}

@Pipe({ name: 'paymentInstallments' })
export class PaymentInstallments implements PipeTransform {

  transform(product: Product) {

    let priceTable = product.prices && product.prices.length > 0 && product.prices[0] ? product.prices[0].priceTable : null;
    let price = product.prices && product.prices.length > 0 && product.prices[0] ? product.prices[0].price : 0;
    let currency = priceTable && priceTable.currency ? priceTable.currency.alias : 'BRL';

    

    if (product.paymentMethods && product.paymentMethods.length > 0) {
      const orderedPaymentMethods = product.paymentMethods.sort((a, b) => b.paymentMethod.methodQuota - a.paymentMethod.methodQuota);
      const maxQuota = orderedPaymentMethods[0];
      if (maxQuota && maxQuota.paymentMethod.methodQuota > 0) {

        let new_value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency }).format(price / maxQuota.paymentMethod.methodQuota);
        new_value.replace('.', '|').replace(',', '.').replace('|', ',');
        
        return `${maxQuota.paymentMethod.methodQuota}x de ${new_value} sem juros no cartão de crédito`
      }

      return null;
    }

    return null;
  }

}