import {
    Entity,
    PrimaryGeneratedColumn,
    Column, OneToMany, ManyToOne, JoinColumn, AfterLoad, Unique
} from "typeorm";
import { Situation } from "../enums/situation.enum";
import Complement from "./complement.entity";
import ProductSchemeComplement from "./productSchemeComplement.entity";
import Product from "./product.entity";
import ProductComplementVariation from "./productComplementVariation.entity";

@Entity({ orderBy: { sequence: 'ASC' } })
@Unique("product_seq", ["product", "sequence"])
export default class ProductScheme {
    @PrimaryGeneratedColumn()
    public idProductScheme: number;

    @ManyToOne((type) => ProductComplementVariation, { eager: true, cascade: true, onDelete: "SET NULL" })
    @JoinColumn({ name: "idProductComplementVariation", referencedColumnName: "idProductComplementVariation" })
    public variation: ProductComplementVariation;

    @ManyToOne((type) => Product, { eager: false, cascade: true, onDelete: "CASCADE" })
    @JoinColumn({ name: "idProduct", referencedColumnName: "idProduct" })
    public product: Product;

    @OneToMany(() => ProductSchemeComplement, pc => pc.productScheme, { eager: true })
    public productSchemeComplements: ProductSchemeComplement[];

    @Column()
    public sequence: number;

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;

    @AfterLoad()
    sortAttributes() {
        if (this?.productSchemeComplements?.length) {
            this.productSchemeComplements.sort((a, b) => a.sequence - b.sequence);
        }
    }
}
