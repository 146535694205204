import {
    Entity,
    PrimaryGeneratedColumn,
    Column, ManyToOne, JoinColumn, OneToMany
} from "typeorm";
import CartItem from "./cartItem.entity";
import CartDiscounts from "./cartDiscount.entity";
import PaymentMethod from "./paymentMethod.entity";
import User from "./user.entity";
import Product from "./product.entity";
import { OfferApplicationType } from "../enums/offerApplicationType.enum";

@Entity()
export default class Cart {
    @PrimaryGeneratedColumn()
    public idCart: number;

    @Column({ name: "cartCode" })
    public cartCode: string;

    @ManyToOne((type) => User, { eager: false, cascade: true, onDelete: "CASCADE" })
    @JoinColumn({ name: "idUser", referencedColumnName: "idUser" })
    public user: User;

    @ManyToOne((type) => PaymentMethod, { eager: true, cascade: true, onDelete: "CASCADE" })
    @JoinColumn({ name: "idPaymentMethod", referencedColumnName: "idPaymentMethod" })
    public paymentMethod: PaymentMethod;

    @OneToMany(() => CartDiscounts, type => type.cart, { eager: true, cascade: true, onDelete: "CASCADE" })
    public cartDiscounts: CartDiscounts[];

    @Column({ name: "totalPrice", type: "decimal", precision: 10, scale: 2, default: 0 })
    public totalPrice: Number;

    @Column({ name: "unitPrice", type: "decimal", precision: 10, scale: 2, default: 0 })
    public unitPrice: Number;

    @OneToMany(() => CartItem, type => type.cart, { eager: true, cascade: true })
    public itens: CartItem[];

    get cartValue(): number {
        let value = 0;
        for (const item of this.itens) {
            value += item.productPrice;
        }
        return value;
    }

    get cartUnitValue(): number {
        let value = 0;
        for (const item of this.itens) {
            value += item.productUnitPrice;
        }
        this.unitPrice = value;
        return value;
    }
}
