import {Component, Input, OnInit} from '@angular/core';
import {AppPageItem} from '../../app.component';
import {NavController} from "@ionic/angular";

@Component({
  selector: 'app-menu-dropdown-item',
  templateUrl: './menu-dropdown-item.page.html',
  styleUrls: ['./menu-dropdown-item.page.scss'],
})
export class MenuDropdownItemPage implements OnInit {

  @Input() pageItem: AppPageItem;

  constructor(private navCtrl: NavController) { }

  ngOnInit() {
    if (!this.pageItem.icon){
      this.pageItem.icon = 'help';
    }
  }

  navigate(item){
    if(item.item.length === 0){
      this.navCtrl.navigateForward(item.url);
    }
  }

}
