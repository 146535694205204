import { Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CustomComponentsModule } from './components/custom-components/custom-components.module';
import { JwtModule } from '@auth0/angular-jwt';
import { EncryptStorage, EncryptStorageOptions } from 'encrypt-storage';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth/auth.service';
import { SessionService } from './services/auth/session.service';
import { ProdutoService } from './services/product/produto.service';
import {MetaHeaderConfigurationService} from './services/meta-header-configuration.service';
import {MomentModule} from 'ngx-moment';
import { CategoryService } from './services/category/category.service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { CommonModule } from '@angular/common';
import CartService from './services/cart/cart.service';
import { ProdutoVitrineService } from './services/product/showcase/produto.vitrine.service';
export const encryptStorage = new EncryptStorage('M0n0$C00f33_T@k3n', {
  storageType: 'localStorage',
  stateManagementUse: true
});

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let apiReq: any = null;
    if(req.url.search('http') === -1 && req.url.search('https')  === -1) {
      apiReq = req.clone({url: `${environment.apiBaseUrl}${req.url}`});
    }else{
      apiReq = req.clone({ url: `${req.url}` });
    }
    return next.handle(apiReq);
  }
}

const maskConfigFunction: () => Partial<IConfig> = () => ({
    validation: false,
  });


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
    CommonModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    CustomComponentsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => getToken(),
        // eslint-disable-next-line max-len
        allowedDomains: ['lucasstore.sytes.net','localhost:8080', 'localhost:8081', '192.168.0.240:8081', 'viacep.com.br','mightnetwork.hopto.org','mightnetwork.hopto.org:8080','mightstore.vercel.app','apimightstore.vercel.app','api.lojamight.com.br'],
        headerName: 'csrfmiddlewaretoken',
        authScheme: ''
      },
    }),
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59
      },
    })
  ],
  providers: [
    AuthService,
    SessionService,
    MetaHeaderConfigurationService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    ProdutoService,
    ProdutoVitrineService,
    CategoryService,
    CustomComponentsModule,
    CartService,
    {provide: LOCALE_ID, useValue: 'pt-BR'}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}

const getToken = async () => new Promise<any>((resolve, reject) => {
  try{
    resolve(encryptStorage.getItem('TOKEN'));
  }catch (e) {
    encryptStorage.removeItem('TOKEN');
    window.location.reload();
    resolve(null);
  }
});
