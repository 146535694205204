import { OfferCombinationType } from "../enums/offerKeyType.enum";
import {
    Entity,
    PrimaryGeneratedColumn,
    Column
} from "typeorm";

@Entity()
export default class OfferCombination {
    @PrimaryGeneratedColumn()
    public idOfferCombination: number;

    @Column({ name: "offerCombinationName" })
    public offerCombinationName: string;

    @Column({ type: 'enum', enum: OfferCombinationType, default: OfferCombinationType.PRODUCT })
    public combinationType: OfferCombinationType;
}
