import { Situation } from "../enums/situation.enum";
import { Column, Entity, JoinColumn, ManyToOne, PrimaryColumn, PrimaryGeneratedColumn, Unique } from "typeorm";
import Complement from "./complement.entity";
import Product from "./product.entity";
import ProductScheme from "./productScheme.entity";
import ProductComplementVariation from "./productComplementVariation.entity";

@Entity({ orderBy: { sequence: "DESC", } })
@Unique("scheme_complement_seq", ["productScheme", "complement", "sequence"])
export default class ProductSchemeComplement {

    @PrimaryGeneratedColumn()
    public idProductSchemeComplement: number;

    @ManyToOne((type) => Complement, { eager: true, cascade: true, onDelete: "CASCADE"})
    @JoinColumn({ name: "idComplement", referencedColumnName: "idComplement" })
    public complement: Complement;

    @ManyToOne((type) => ProductScheme, { eager: false, cascade: true, onDelete: "CASCADE" })
    @JoinColumn({ name: "idProductScheme", referencedColumnName: "idProductScheme" })
    public productScheme: ProductScheme;

    @ManyToOne((type) => ProductComplementVariation, { eager: true, cascade: true, onDelete: "CASCADE" })
    @JoinColumn({ name: "idProductComplementVariation", referencedColumnName: "idProductComplementVariation" })
    public variation: ProductComplementVariation;

    @Column()
    public sequence: number;

    @Column()
    public image: string;

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;
}
