import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import {IonSearchbar, LoadingController, NavController} from '@ionic/angular';
import { Platform } from '@ionic/angular';
import {BehaviorSubject} from 'rxjs';
import {SessionService} from '../../services/auth/session.service';
import {EventHandler} from '../../services/utils/event.handler.ts.service';
import CartService from 'src/app/services/cart/cart.service';
import CartItem from '@shared/entities/cartItem.entity';
import Product from '@shared/entities/product.entity';
import ProductDetail from '@shared/entities/DTO/productDetail.dto';

@Component({
  selector: 'app-ion-menu-header',
  templateUrl: './ion-menu-header.component.html',
  styleUrls: ['./ion-menu-header.component.scss'],
})
export class IonMenuHeaderComponent implements OnInit,OnDestroy {

  @Input()
  public hasSearchBar: 'true' | 'false' = 'false';

  @Input()
  public searchType: 'product' | 'page';

  @Input()
  public hasBackBtn: 'true' | 'false' = 'false';

  @Input()
  public backBtnDefaultHref = '/home';

  public placeHolder = 'Pesquisar';

  public environment = environment;

  public platformName = 'md';

  public isPopoverOpen = false;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('searchBar') searchBar: IonSearchbar;


  constructor(
    private loadingCtrl: LoadingController,
    private platformCtrl: Platform,
    private event: EventHandler,
    public sessionService: SessionService,
    public cartService: CartService,
    public navCtrl: NavController
  ) {

  }

  ngOnInit() {

    // this.event.subscribe('ADDCART', (value: ProductDetail) => {
    //   this.cartService.addItem(value);
    // });

    this.platformName = this.platformCtrl.is('ios') ? 'ios' : 'md';

    if(this.searchType === 'product'){
      this.placeHolder = 'Pesquisar produto';
    }

    if(this.searchType === 'page'){
      this.placeHolder = 'Pesquisar página';
    }

  }

  ngOnDestroy(){
    this.event.unsubscribe('ADDCART');
  }

  public async search(event){

    if (event.target.value.length >= 1){

      const loading = await this.loadingCtrl.create({
        mode: 'ios',
        spinner: 'crescent',
        message: 'Pesquisando'
      });

      // await loading.present();

      this.searchBar.searchIcon = 'hourglass-outline';
      this.searchBar.cancelButtonIcon = 'hourglass-outline';
      this.searchBar.disabled = true;
      this.isPopoverOpen = !this.isPopoverOpen;

      setTimeout(async () => {
        await loading.dismiss();
        this.searchBar.searchIcon = 'search-outline';
        this.searchBar.cancelButtonIcon = 'arrow-back-outline';
        this.searchBar.disabled = false;
        this.isPopoverOpen = true;
      }, 1000);

    }

  }

  public teste(){
    alert('Ok');
  }

  public goTo(path){
    this.navCtrl.navigateForward(path);
  }

}
