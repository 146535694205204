import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import Cart from "@shared/entities/cart.entity";
import CartItem from "@shared/entities/cartItem.entity";
import ProductDetail from "@shared/entities/DTO/productDetail.dto";
import Product from "@shared/entities/product.entity";
import { encryptStorage } from "src/app/app.module";
import { SessionService } from "../auth/session.service";

@Injectable({
  providedIn: 'root'
})
export default class CartService {

  public cart: Cart;

  constructor(
    public session: SessionService,
    private http: HttpClient
  ) {

    if (!this.session.isSessionExpired && session.user && session.user.userCode) {
      try {
        this.cart = Object.assign(new Cart(), JSON.parse(encryptStorage.getItem(`CART-${session.user.userCode}`)));
      } catch (error) {
        this.cart = new Cart();
      }
    }

    if (!this.cart) {
      this.cart = new Cart();
      this.cart.itens = [];
    }

    console.log(this.cart);

    this.saveCart();
  }

  public addItem(cartItem: CartItem) {
    if (!this.cart.itens) {
      this.cart.itens = [];
    }

    this.cart.itens.push(cartItem);
    this.saveCart();
  }

  public removeItem(cartItem: CartItem) {
    this.cart.itens.splice(this.cart.itens.findIndex(item => item === cartItem), 1);
    this.saveCart();
  }

  public saveCart() {
    if (!this.session.isSessionExpired) {
      encryptStorage.setItem(`CART-${this.session.user.userCode}`, this.cart);
    }
  }

  public clearCart(){
    if (!this.session.isSessionExpired) {
      this.cart = new Cart();
      this.cart.itens = [];
      encryptStorage.setItem(`CART-${this.session.user.userCode}`, this.cart);
      this.saveCart();
    }
  }

  public getItemImages(cartItem: CartItem): Array<string> {
    const pictures = new Array<string>();
    if (cartItem.product) {
      cartItem.productComplements.forEach(complement => {
        if (complement.image.length >= 3) {
          if (complement.image.split(';').length > 0) {
            for (const img of complement.image.split(';')) {
              pictures.push(img);
            }
          } else {
            pictures.push(complement.image);
          }
        }
      });
    }
    return pictures;
  }
}