import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PrecoPrazoRequest, PrecoPrazoResponse } from '@shared/entities/DTO/correios.dto';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { switchMap } from 'rxjs/operators';
import { BuscaCEPService } from './buscaCEP.service';

@Injectable({
  providedIn: 'root'
})
export class FreteService {

  public proxy = environment.production ? '' : 'https://icity-cors.herokuapp.com/';
  public precoPrazoBaseURL = 'http://ws.correios.com.br/calculador/CalcPrecoPrazo.asmx/CalcPrecoPrazo';

  constructor(
    private http: HttpClient,
    private ngxXml2jsonService: NgxXml2jsonService,
    private buscaCep: BuscaCEPService
  ) { }

  public async calcularPrecoPrazo(precoPrazo: PrecoPrazoRequest): Promise<PrecoPrazoResponse> {

    const address = await this.buscaCep.fetch(precoPrazo.sCepDestino);
    if (address && address.uf) {
      precoPrazo.sCepDestino = precoPrazo.sCepDestino.replace('-', '');

      const url = Object.keys(precoPrazo)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(precoPrazo[key])}`)
        .join('&');

      // eslint-disable-next-line max-len
      const data: PrecoPrazoResponse = await this.http.get<PrecoPrazoResponse>(`${this.proxy}${this.precoPrazoBaseURL}?${url}`, this.createHeader('text/xml')).pipe(
        switchMap(async xml => await this.parseXmlToJson(xml))
      ).toPromise();
      data.cResultado.Servicos.cServico.Bairro = address.bairro;
      data.cResultado.Servicos.cServico.Complemento = address.complemento;
      data.cResultado.Servicos.cServico.Localidade = address.localidade;
      data.cResultado.Servicos.cServico.Uf = address.uf;
      data.cResultado.Servicos.cServico.Logradouro = address.logradouro ? address.logradouro : null;
      console.log(data);
      return data;
    } else {
      // eslint-disable-next-line max-len
      const data: PrecoPrazoResponse = JSON.parse('{ "cResultado": { "Servicos": { "cServico": {"Erro": "999","MsgErro": "Verifique o CEP"} } } }');
      return data;
    }
  }

  private createHeader(contentType: string): any {
    return { headers: new HttpHeaders({ 'Content-Type': contentType }), responseType: 'text' };
  }

  private parseXmlToJson(xml: any): PrecoPrazoResponse{
    const parser = new DOMParser();
    xml = parser.parseFromString(xml, 'text/xml');
    const data: any = this.ngxXml2jsonService.xmlToJson(xml);
    return data;
  }

}
