import {
    Entity,
    PrimaryGeneratedColumn,
    Column, ManyToMany, JoinTable
} from "typeorm";
import {Situation} from "../enums/situation.enum";

@Entity()
export default class Currency {
    @PrimaryGeneratedColumn()
    public idCurrency: number;

    @Column()
    public alias: string;

    @Column({ type: "text"})
    public name: string;

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;
}
