import { Injectable } from '@angular/core';
import User from '@shared/entities/user.entity';
import { NavController, ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { EventHandler } from '../utils/event.handler.ts.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { encryptStorage } from '../../app.module';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export class UserSession {

  public token: string;
  public fingerprint: string;

}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public helper = new JwtHelperService();

  private $session: UserSession;

  private isLoading = false;

  constructor(
    private events: EventHandler,
    private http: HttpClient,
    private toastController: ToastController,
    private navCtrl: NavController
  ) {

    this.checkSessionIsValid(false);

  }

  public get session(): UserSession {
// if (this.isSessionExpired) {
//   this.sessionExpiredAlert().then(() => { });
//   return null;
// }
    return this.$session;
  }

  public get user(): User {
    try {
      if (this.session) {
        try {
          const parsedToken = this.helper.decodeToken(this.session?.token);
          return parsedToken;
        }catch (e) {
          this.removeSession();
          return null;
        }
      }
      return null;
    } catch (e) {
      this.removeSession();
      return null;
    }
  }

  get isSessionExpired() {
    return this.helper.isTokenExpired(this.session?.token);
  }

  async status(session: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let data = '';
      try {
        data = encryptStorage.getItem(session);
        resolve(data);
      } catch (e) {
        reject(e);
      }
      return data;
    });
  }

  public async sessionExpiredAlert() {
    const toast = await this.toastController.create({
      message: 'Sua sessão expirou. Efetue login novamente!',
      position: 'top',
      color: 'danger',
      duration: 4000,
      buttons: [
        {
          text: 'Login',
          side: 'end',
          icon: 'log-in-outline',
          handler: () => {
            this.navCtrl.navigateRoot(['/conta']);
          }
        }
      ]
    });
    await toast.present();
  }

  public save(session: UserSession) {
    encryptStorage.setItem('TOKEN', session);
    this.$session = session;
    console.info('[Session]', `Sessão de ${this.user.firstName} salva com sucesso!`, session);
    this.navCtrl.navigateRoot(['/home']);
    this.events.publish('AUTH', 'refresh');
  }

  public removeSession(): void {
    encryptStorage.removeItem('TOKEN');
    this.events.publish('AUTH', 'removed');
    this.$session = null;
    this.navCtrl.navigateRoot(['/conta']);
    console.info('[Session]', `Sessão encerrada!`);
    //console.log("[INFO] User session destroyed!");
  }

  public checkSessionIsValid(needsAuth: boolean = false){
    try {
      const sessionAux = encryptStorage.getItem('TOKEN');
      if (sessionAux) {

        this.$session = JSON.parse(sessionAux);

        const fpPromise = FingerprintJS.load();
        fpPromise.then(async fp => {
          const result = await fp.get();
          if (result.visitorId !== this.$session.fingerprint){

            //IMPORTANTE ATIVAR
            this.sessionExpiredAlert().then( () => {});
            this.removeSession();
            return;

          }

          try {
            if (this.$session) {
              const parsedToken = this.helper.decodeToken(this.$session?.token);
              if (!parsedToken){
                //IMPORTANTE ATIVAR
                this.sessionExpiredAlert().then( () => {});
                this.removeSession();
                return;
              }
            }
          } catch (e) {
            //IMPORTANTE ATIVAR
            this.sessionExpiredAlert().then( () => {});
            this.removeSession();
            return;
          }

          if(this.isSessionExpired){

            //IMPORTANTE ATIVAR
            this.sessionExpiredAlert().then( () => {});
            this.removeSession();
            return;

          }

        });

      }else{
        if(needsAuth){
          //IMPORTANTE ATIVAR
          this.sessionExpiredAlert().then( () => {});
          this.removeSession();
          return;
        }
      }

    } catch (e) {
      this.$session = null;
    }
  }

}
