import { Situation } from "../enums/situation.enum";
import {
    Entity,
    PrimaryGeneratedColumn,
    Column,
    ManyToMany,
    JoinTable,
    ManyToOne,
    JoinColumn
} from "typeorm";
import Offer from "./offer.entity";
import OfferCombination from "./offerCombination.entity";
import Complement from "./complement.entity";
import PriceTable from "./priceTable.entity";
import Product from "./product.entity";
import PaymentMethod from "./paymentMethod.entity";

@Entity()
export default class OfferRule {
    @PrimaryGeneratedColumn()
    public idOfferRule: number;

    @Column({ name: "offerRuleName" })
    public offerRuleName: string;

    @ManyToMany(() => OfferCombination, combination => combination.idOfferCombination, { eager: true })
    @JoinTable()
    public combinations: OfferCombination[];

    @ManyToOne((type) => Offer, { eager: true, nullable: false, onDelete: "CASCADE" })
    @JoinColumn({ name: "idOffer", referencedColumnName: "idOffer" })
    public offer: Offer;

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;

    @ManyToOne((type) => Product, { eager: true, cascade: true, onDelete: "CASCADE" })
    @JoinColumn({ name: "idProduct", referencedColumnName: "idProduct" })
    public product: Product;

    @ManyToMany(() => Complement, complement => complement.idComplement, { eager: true, nullable: true })
    @JoinTable()
    public complements: Complement[];

    @ManyToOne((type) => PriceTable, { eager: true, cascade: true, nullable: true })
    @JoinColumn({ name: "idPriceTable", referencedColumnName: "idPriceTable" })
    public priceTable: PriceTable;
    
    @Column({ name: "minDate", nullable: true })
    public minDate: Date;

    @Column({ name: "minQuantity", nullable: true })
    public minQuantity: number;

    @Column({ name: "maxQuantity", nullable: true })
    public maxQuantity: number;

    @Column({ name: "maxDate", nullable: true })
    public maxDate: Date;

    @ManyToOne((type) => PaymentMethod, { eager: true, cascade: true, onDelete: "CASCADE" })
    @JoinColumn({ name: "idPaymentMethod", referencedColumnName: "idPaymentMethod" })
    public paymentMethod: PaymentMethod;

    public offerDiscountValue(price: number) {
        if (this.isDiscountPercentage()) {
            const value = price - (price * (this.offer.discountPercentage / 100));
            return value <= 0.00 ? 0.00 : value;
        } else {
            const value = price - this.offer.discountAmount;
            return value <= 0.00 ? 0.00 : value;
        }
    }

    public isDiscountPercentage() {
        if (this.offer.discountPercentage && this.offer.discountPercentage > 0.00) {
            return true;
        } else {
            return false;
        }
    }
}
