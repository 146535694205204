import {
    Entity,
    PrimaryGeneratedColumn,
    ManyToOne, JoinColumn
} from "typeorm";
import Cart from "./cart.entity";
import OfferRule from "./offerRule.entity";

@Entity()
export default class CartDiscounts {
    @PrimaryGeneratedColumn()
    public idCartItemDiscount: number;

    @ManyToOne((type) => OfferRule, { eager: true, nullable: false, onDelete: "CASCADE" })
    @JoinColumn({ name: "idOfferRule", referencedColumnName: "idOfferRule" })
    public offerRule: OfferRule;

    @ManyToOne((type) => Cart, { eager: false, nullable: false, onDelete: "CASCADE" })
    @JoinColumn({ name: "idCart", referencedColumnName: "idCart" })
    public cart: Cart;
}
