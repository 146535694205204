import {Component, Input, OnInit} from '@angular/core';
import {NavController, ToastController} from '@ionic/angular';
import Product from '@shared/entities/product.entity';
import UserFavorite from '@shared/entities/userFavorite.entity';
import { UserFavoriteService } from "../../services/user/userFavorite.service";
import { WSResponse } from '@shared/entities/DTO/wsResponse.dto';
import ProductShowcase from '@shared/entities/DTO/productShowcase.dto';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {

  isLoadingFav = true;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  public productShowcase: ProductShowcase;
  public productObj: Product;

  public pictures = [];

  public viewerOptions: any = {
    navbar: true,
    button: true,
    toolbar: {
      prev: 4,
      next: 4,
    }
  };

  constructor(
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private favoriteService: UserFavoriteService
  ) {

  }

  ngOnInit() {
    this.productObj = this.productShowcase?.product;

    if (this.productObj){
      this.pictures = Object.assign(new Product(), this.productObj).productImages();
    }

    this.isLoadingFav = true;
    setTimeout(async () => {
      this.isLoadingFav = false;
    }, 500);

  }

  public async addToFavorites() {
    this.isLoadingFav = true;
    this.favoriteService.addUserFavorite(this.productObj.sku).then(data => {
      this.productObj.favorito = data.data;
      this.presentToast();
      this.isLoadingFav = false;
    }).catch(async (error) => {
      if (error.status === 0) {
        const toast = await this.toastCtrl.create({
          color: 'danger',
          message: 'Não foi possível conectar-se com o servidor',
          duration: 2000,
          position: 'top'
        });
        await toast.present();
      } else {
        const toast = await this.toastCtrl.create({
          color: 'danger',
          message: error.error ? error.error.message : error.error,
          duration: 2000,
          position: 'top',
          buttons: [
            {
              text: 'VER',
              handler: async () => {
                await this.navCtrl.navigateForward('/favoritos');
              }
            }
          ]
        });
        await toast.present();
      }
      this.isLoadingFav = false;
    });

  }

  public async removeFromFavorites() {
    try {
      this.isLoadingFav = true;
      const unfavResp = await this.favoriteService.deleteUserFavorite(this.productObj.sku);
      this.productObj.favorito = unfavResp.data;
      this.presentToast();
      this.isLoadingFav = false;
    } catch (error) {
      if (error.status === 0) {
        const toast = await this.toastCtrl.create({
          color: 'danger',
          message: 'Não foi possível conectar-se com o servidor',
          duration: 2000,
          position: 'top'
        });
        await toast.present();
      } else {
        const toast = await this.toastCtrl.create({
          color: 'danger',
          message: error.error ? error.error.message : error.error,
          duration: 2000,
          position: 'top',
          buttons: [
            {
              text: 'VER',
              handler: async () => {
                await this.navCtrl.navigateForward('/favoritos');
              }
            }
          ]
        });
        await toast.present();
      }
      this.isLoadingFav = false;
    }
  }

  async presentToast() {
    let toast = null;
    if (this.productObj.favorito) {
      toast = await this.toastCtrl.create({
        color: 'primary',
        message: `Item ' ${this.productObj.name} ' adicionado aos favoritos`,
        duration: 2000,
        position: 'top',
        buttons: [
          {
            text: 'VER',
            handler: async () => {
              await this.navCtrl.navigateForward('/favoritos');
            }
          }
        ]
      });
    } else {
      toast = await this.toastCtrl.create({
        color: 'primary',
        message: `Item ' ${this.productObj.name} ' removido dos favoritos`,
        duration: 2000,
        position: 'top',
        buttons: [
          {
            text: 'VER',
            handler: async () => {
              await this.navCtrl.navigateForward('/favoritos');
            }
          }
        ]
      });
    }
    await toast.present();
  }

  async navigateTo(url){
    await this.navCtrl.navigateForward(url);
  }

  public async toggleFavorite() {
    if (this.isLoadingFav) {
      return;
    }

    if (this.productObj.favorito) {
      await this.removeFromFavorites();
    } else {
      await this.addToFavorites();
    }
  }

}
