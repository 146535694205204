import { Component, Input, OnInit } from '@angular/core';
import QRCodeStyling from 'qr-code-styling';

@Component({
  selector: 'qr-component',
  templateUrl: './qr-component.component.html',
  styleUrls: ['./qr-component.component.scss'],
})
export class QrComponentComponent implements OnInit {

  @Input()
  public data: string;

  @Input()
  public options?: { width: number, height: number };

  constructor() {

  }

  ngOnInit() {
    if (!this.options) {
      this.options = {height: 250, width: 250}
    }
    if (this.options) {
      if (!this.options.height || this.options.height <= 0) {
        this.options.height = 250;
      }
    }
    if (this.options) {
      if (!this.options.width || this.options.width <= 0) {
        this.options.width = 250;
      }
    }
  }

  ngAfterViewInit() {



    const qrCode = new QRCodeStyling(
      {
        "width": this.options.width,
        "height": this.options.height,
        "data": this.data,
        "margin": 2,
        "qrOptions": {
          "typeNumber": 0,
          "mode": "Byte",
          "errorCorrectionLevel": "Q"
        },
        "imageOptions": {
          "hideBackgroundDots": true,
          "imageSize": 0.6,
          "margin": 0
        },
        "dotsOptions": {
          "type": "rounded",
          "color": getComputedStyle(document.documentElement).getPropertyValue('--ion-color-qr'),
          "gradient": {
            "type": "radial",
            "rotation": 0,
            "colorStops": [
              {
                "offset": 0,
                "color": getComputedStyle(document.documentElement).getPropertyValue('--ion-color-qr')
              },
              {
                "offset": 1,
                "color": getComputedStyle(document.documentElement).getPropertyValue('--ion-color-qr')
              }
            ]
          }
        },
        "backgroundOptions": {
          "color": "#FFFF",
          "gradient": null
        },
        "image": getComputedStyle(document.documentElement).getPropertyValue('--logo-icon-src-url'),
        "cornersSquareOptions": {
          "type": "extra-rounded",
          "color": getComputedStyle(document.documentElement).getPropertyValue('--ion-color-qr')
        },
        "cornersDotOptions": {
          "type": "dot",
          "color": getComputedStyle(document.documentElement).getPropertyValue('--ion-color-qr'),
        }
      }
    );

    qrCode.append(document.getElementById("qrCanvas"));

  }

}
