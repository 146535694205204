import {
    Entity,
    PrimaryGeneratedColumn,
    Column, ManyToOne, JoinColumn
} from "typeorm";
import { Situation } from "../enums/situation.enum";
import Product from "./product.entity";

@Entity({
    orderBy: {
        sequence: 'DESC'
    }
})
export default class ProductAttributes {
    @PrimaryGeneratedColumn()
    public idProductAttribute: number;

    @Column({ type: "text" })
    public name: string;

    @Column({ type: "text", nullable: true })
    public value: string;

    @Column({ type: "text", nullable: true })
    public description: string;

    @Column()
    public sequence: number;

    @ManyToOne((type) => Product, { eager: false, cascade: true, onDelete: "CASCADE" })
    @JoinColumn({ name: "idProduct", referencedColumnName: "idProduct" })
    public product: Product;

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;
}
