import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonMenuHeaderComponent} from '../ion-menu-header/ion-menu-header.component';
import {IonicModule} from '@ionic/angular';
import {ShowHidePassComponent} from '../show-hide-pass/show-hide-pass.component';
import {MenuDropdownItemPage} from '../menu-dropdown-item/menu-dropdown-item.page';
import {ProductCardComponent} from '../product-card/product-card.component';
import {FooterComponent} from '../footer/footer.component';
import {RatingComponent} from '../rating/rating.component';
import {CookiesPromptComponent} from '../cookies-prompt/cookies-prompt.component';
import {RouterModule} from '@angular/router';
import {PrivacyPolicyPageModule} from '../../pages/privacy-policy/privacy-policy.module';
import {PrivacyPolicyPage} from '../../pages/privacy-policy/privacy-policy.page';
import {MomentModule} from 'ngx-moment';
import {EventHandler} from '../../services/utils/event.handler.ts.service';
import {IonicMaskDirective} from '../../directives/ionicmaskdirective.directive';
import {MaskPipe} from 'ngx-mask';
import { LimitTextLength, ProductPrice, PaymentInstallments, ProductDiscount, CartItemPrice } from '../../services/utils/text-format.service';
import { CalculoFreteModalPage } from '../calculo-frete-modal/calculo-frete-modal.page';
import { CalculoFreteModalPageModule } from '../calculo-frete-modal/calculo-frete-modal.module';
import { ImageViewerDirective } from 'src/app/directives/image-viewer.directive';
import { QrComponentComponent } from '../qr-component/qr-component.component';

const PAGES_COMPONENTS = [
  IonMenuHeaderComponent,
  ShowHidePassComponent,
  MenuDropdownItemPage,
  ProductCardComponent,
  FooterComponent,
  RatingComponent,
  CookiesPromptComponent,
  IonicMaskDirective,
  CalculoFreteModalPage,
  ImageViewerDirective,
  QrComponentComponent
];

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    RouterModule,
    MomentModule
  ],
  declarations: [
    PAGES_COMPONENTS,
    LimitTextLength,
    ProductPrice,
    ProductDiscount,
    CartItemPrice,
    PaymentInstallments,
    ImageViewerDirective
  ],
  exports: [
    PAGES_COMPONENTS,
    LimitTextLength,
    ProductPrice,
    ProductDiscount,
    CartItemPrice,
    PaymentInstallments,
    ImageViewerDirective
  ],
  providers: [
    EventHandler, IonicMaskDirective, MaskPipe, ProductPrice, PaymentInstallments, ImageViewerDirective
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CustomComponentsModule { }
