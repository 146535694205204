import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { PrecoPrazoRequest, PrecoPrazoResponse } from '@shared/entities/DTO/correios.dto';
import { FreteService } from 'src/app/services/correios/frete.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-calculo-frete-modal',
  templateUrl: './calculo-frete-modal.page.html',
  styleUrls: ['./calculo-frete-modal.page.scss'],
})
export class CalculoFreteModalPage implements OnInit {

  @Input() cep: string;

  public isCepLoading = true;

  public isError = false;

  public selectedItem = '';
  public selectedItemPrice: number;

  public resultadoCep: PrecoPrazoResponse = null;

  constructor(
    private correios: FreteService,
    private modalController: ModalController,
    private teastCtrl: ToastController
  ) { }

  async ngOnInit() {

    if (this.cep.length >= 9) {
      this.isCepLoading = true;
      const request: PrecoPrazoRequest = new PrecoPrazoRequest();
      request.nCdFormato = '1';
      request.nCdServico = ['04014'];
      request.nVlPeso = '0.3';
      request.sCepOrigem = environment.sCepOrigem;
      request.sCepDestino = this.cep;
      request.nVlComprimento = '30';
      request.nVlAltura = '3';
      request.nVlLargura = '30';
      request.nVlDiametro = '0';
      try {
        this.resultadoCep = await this.correios.calcularPrecoPrazo(request);
        this.isError = false;
      } catch (error) {
        this.resultadoCep = null;
        this.isError = true;
      }
      this.isCepLoading = false;
    }

  }

  async select() {
    // if (this.selectedItem === '') {
    //   const toast = await this.teastCtrl.create(
    //     {
    //       message: 'Selecione uma opção de frete',
    //       color: 'danger',
    //       duration: 2000,
    //       position: 'top'
    //     }
    //   );
    //   await toast.present();
    // }else {
      this.modalController.dismiss({ frete: this.selectedItem, valor: this.selectedItemPrice });
    // }
  }

  selectItem(event: any) {
    this.selectedItem = event.target.value;
    if (this.selectedItem.toLocaleLowerCase() === 'sedex') {
      this.selectedItemPrice = parseFloat(this.resultadoCep.cResultado.Servicos.cServico.Valor.replace(',', '.'));
    }
  }

}
