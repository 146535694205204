import { Injectable } from '@angular/core';

// @ts-ignore
@Injectable({
  providedIn: 'root'
})

export interface Config {
  author?: string;
  description?: string;
  ogImage?: string;
  ogDescription?: string;
  ogTitle?: string;
}

export class MetaHeaderConfigurationService {

  constructor() { }

  set(config: Config){
    if(config.author.length > 0){document.getElementById('author').setAttribute('content', config.author);}
    if(config.description.length > 0){document.getElementById('description').setAttribute('content', config.description);}
    if(config.ogImage.length > 0){document.getElementById('ogImage').setAttribute('content', config.ogImage);}
    if(config.ogDescription.length > 0){document.getElementById('ogDescription').setAttribute('content', config.ogDescription);}
    if(config.ogTitle.length > 0){document.getElementById('ogTitle').setAttribute('content', config.ogTitle);}
  }

}
