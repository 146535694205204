import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageViewerPage } from '../components/image-viewer/image-viewer.page';

@Directive({
  selector: '[ImageViewer]'
})
export class ImageViewerDirective {

  private images: Array<{ src: string, title: string }> = [];

  private imagesElements: any;

  private originalSrc = '';
  private hasError = false;

  constructor(public modalCtrl: ModalController, private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.imagesElements = this.elementRef.nativeElement.querySelectorAll("img");

    if(this.imagesElements.length < 1){
      this.imagesElements = this.elementRef.nativeElement;
    }

    for (var i = 0; i < this.imagesElements.length; i++) {
      this.imagesElements[i].addEventListener('click', async (event) => {

        this.images = [];
        this.imagesElements.forEach(element => {
          this.images.push({src: element.src, title: element.alt});
        });
        
        const modal = await this.modalCtrl.create({
          component: ImageViewerPage,
          cssClass: 'auto-height',
          componentProps: {
            src: JSON.stringify(this.images),
            selectedSrc: event.target.src
          }
        });

        return await modal.present();

      });
    }

  }

  @HostListener('error')
  failure() {
    this.hasError = true;
  }

  @HostListener('click') async onClick() {

    this.images = [];

    if (!this.imagesElements || this.imagesElements.length < 1) {} else {
      if(this.imagesElements.src){

        this.images.push({src: this.imagesElements.src, title: ''});
        const modal = await this.modalCtrl.create({
          component: ImageViewerPage,
          cssClass: 'auto-height',
          componentProps: {
            src: JSON.stringify(this.images),
            selectedSrc: this.images[0].src
          }
        });
        return await modal.present();

      }
    }

  }

}
