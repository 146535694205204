export interface CepResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  unidade: string;
  ibge: string;
  gia: string;
}
export class PrecoPrazoRequest {
  sCepOrigem: string;
  sCepDestino: string;
  nVlPeso: string;
  nCdFormato: string;
  nVlComprimento: string;
  nVlAltura: string;
  nVlLargura: string;
  nCdServico: Array<string>;
  nVlDiametro: string;

  nCdEmpresa: string = '';
  sDsSenha: string = '';
  sCdMaoPropria: string = 'N';
  nVlValorDeclarado: string = '0';
  sCdAvisoRecebimento: string = 'N';
}
export interface PrecoPrazoEvent {
  Codigo: string;
  Valor: string;
  PrazoEntrega: string;
  ValorSemAdicionais: string;
  ValorMaoPropria: string;
  ValorAvisoRecebimento: string;
  ValorDeclarado: string;
  EntregaDomiciliar: string;
  EntregaSabado: string;
  obsFim: string;
  Erro: string;
  MsgErro: string;
}
export class PrecoPrazoResponse {
  cResultado: {
    Servicos: {
      cServico: {
        Codigo: string;
        Valor: string;
        PrazoEntrega: string;
        ValorSemAdicionais: string;
        ValorMaoPropria: string;
        ValorAvisoRecebimento: string;
        ValorDeclarado: string;
        EntregaDomiciliar: string;
        EntregaSabado: string;
        obsFim: string;
        Erro: string;
        MsgErro: string;
        Uf?: string;
        Localidade?: string;
        Bairro?: string;
        Complemento?: string;
        Logradouro?: string;
      };
    };
  };
}
export interface RastreioEvent {
  status: string;
  data: string;
  hora: string;
  origem?: string;
  destino?: string;
  local?: string;
}
export interface RastreioResponse {
  [name: string]: {
    status: string;
    data: string;
    hora: string;
    origem?: string;
    destino?: string;
    local?: string;
  };
}