import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient
  ) { }

  register(data: object): Promise<any>{
    return this.http.post(`/register.php`, data).toPromise();
  }

  login(data: object): Promise<any>{
    return this.http.post(`/login.php`, data).toPromise();
  }

}
