import { Component, OnInit } from '@angular/core';
import {EventHandler} from '../../services/utils/event.handler.ts.service';
import { encryptStorage } from '../../app.module';
import {UserSession} from '../../services/auth/session.service';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cookies-prompt',
  templateUrl: './cookies-prompt.component.html',
  styleUrls: ['./cookies-prompt.component.scss'],
})

export class CookiesPromptComponent implements OnInit {

  public showPrompt = false;

  public loading = false;

  public expanded = false;

  environment = environment;

  private lastTermsDate: TOSObject = null;

  constructor(
    private events: EventHandler
  ) {

    try {
      const tosobj = encryptStorage.getItem('CookiesParm');
      if (tosobj) {
        this.lastTermsDate = JSON.parse(tosobj);
        this.showPrompt = moment(this.environment.cookiesPolicy).isAfter(this.lastTermsDate.lastDate);
      }else{
        this.lastTermsDate = null;
        this.showPrompt = true;
      }
    } catch (e) {
      this.lastTermsDate = null;
      this.showPrompt = true;
    }

  }

  ngOnInit() {}

  async doAccceptAll() {
    const dataAtual = moment().toDate();
    const aux: TOSObject = new TOSObject();
    aux.lastDate = dataAtual;
    aux.reject = false;
    aux.accept = true;
    await this.save(aux);
    this.lastTermsDate = aux;
    this.showPrompt = false;
  }

  async doRejectAll() {
    const dataAtual = moment().toDate();
    const aux: TOSObject = new TOSObject();
    aux.lastDate = dataAtual;
    aux.reject = true;
    aux.accept = false;
    await this.save(aux);
    this.lastTermsDate = aux;
    this.showPrompt = false;
  }

  public save(parms: TOSObject) {
    encryptStorage.setItem('CookiesParm', parms);
    this.lastTermsDate = parms;
    this.events.publish('CookiesParm', 'refresh');
  }

  doReadMore(){
    this.loading = true;
    setTimeout( () => {
      this.loading = false;
    }, 1000);
    document.getElementById('CookiesPromptContent').style.maxHeight = '100%';
    this.expanded = true;
  }

  doReadLess(){
    this.loading = true;
    setTimeout( () => {
      this.loading = false;
    }, 500);
    this.expanded = false;
    document.getElementById('CookiesPromptContent').style.maxHeight = '14em';
  }

}

export class TOSObject {
  public lastDate;
  public accept: boolean;
  public reject: boolean;
}
