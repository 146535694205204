import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import { environment } from '../environments/environment';
import {MenuController, NavController} from '@ionic/angular';
import { SessionService } from './services/auth/session.service';
import { UserRank } from '@shared/enums/userRank.enum';
import * as moment from 'moment';
import { CategoryService } from './services/category/category.service';
import IAppPageItem from './services/utils/app-page.interface';
import Category from '@shared/entities/category.entity';
import {App} from '@capacitor/app';
import {EventHandler} from './services/utils/event.handler.ts.service';

export class AppPageItem implements IAppPageItem {

  title?: string;
  url?: string;
  icon?: string;
  needsAuth?: boolean;
  permission?: string;
  item?: Array<AppPageItem> = new Array<AppPageItem>();
  count?: number;
  hashCode?: any;

  constructor(b: IAppPageItem = {}) {
    Object.assign(this, b);
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy{

  public environment = environment;

  public userRank = UserRank;

  public appPages = new Array<AppPageItem>();

  public isLoading = true;
  public isLoaded = false;
  public isError = false;

  public cartItemsCount = 0;

  public userPages = [
    {
      title: 'Minha Conta',
      url: '/minhaconta',
      icon: 'person',
      item: [],
      needsAuth: false,
      permission: '',
      count: 0
    },
    {
      title: 'Meus Pedidos',
      url: '/compras',
      icon: 'bag-handle',
      item: [],
      needsAuth: false,
      permission: '',
      count: 0
    },
    {
      title: 'Favoritos',
      url: '/favoritos',
      icon: 'heart',
      item: [],
      needsAuth: false,
      permission: '',
      count: 0
    },
    {
      title: 'Notificações',
      url: '/notificacoes',
      icon: 'notifications',
      item: [],
      needsAuth: false,
      permission: '',
      count: 0
    },
  ];

  public adminPages = [
    {
      title: 'Checkout [TEST]',
      url: '/checkout',
      icon: 'card',
      item: [],
      needsAuth: false,
      permission: '',
      count: 0
    }
  ];

  constructor(
    private menu: MenuController,
    public sessionService: SessionService,
    private categoryService: CategoryService,
    private zone: NgZone,
    private navCtrl: NavController,
    private event: EventHandler) {

    moment.locale('pt');

  }

  async ngOnInit() {

    this.isError = false;
    this.isLoading = true;
    this.isLoaded = false;
    this.appPages = [];
    this.appPages.push(
      {
        title: 'Home',
        url: '/home',
        icon: 'home',
        hashCode: '1',
        needsAuth: false,
        permission: '',
        item: []
      }
    );
    try {
      const categories: Array<Category> = (await this.categoryService.listAllCategories())?.data;

      this.event.subscribe('ADDCART',  (value: any) => {
        alert('ok');
        // this.cartItemsCount = +value;
        // this.userPages[2].count = this.cartItemsCount;
      });

      if (categories) {
        this.montaObj(categories, this.appPages);
        this.appPages.push(
          {
            title: 'Contato',
            url: '/contato',
            icon: 'call',
            needsAuth: false,
            permission: '',
            item: [],
            count: 0
          },
          // {
          //   title: 'Definições',
          //   url: '/definicoes',
          //   icon: 'cog',
          //   needsAuth: false,
          //   permission: '',
          //   item: [],
          //   count: 0
          // }
        );
      }
      this.isError = false;
      this.isLoaded = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }catch (e) {
      this.isLoading = false;
      this.isLoaded = true;
      this.isError = true;
    }

    // setTimeout(()=>{
    //   this.isLoading = false;
    // }, 2000);

    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(async () => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2


        //alert('Working: '+data.url);
        let slug = data.url;
        slug = slug.replace('might://','/');
        if (slug) {
          //alert(slug);
          // this.router.navigateByUrl(slug);
          await this.navCtrl.navigateForward(`${slug}`);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });

  }

  ngOnDestroy(){
    alert('ok');
    this.event.unsubscribe('ADDCART');
  }

  public montaObj(array: Array<Category>, arrayPage: AppPageItem[]) {
    for (const category of array) {
      const appPage = new AppPageItem({
        title: category.categoryName,
        needsAuth: false,
        permission: '',
        url: `/categoria/${category.categoryCode}`,
        icon: category.categoryIcon,
        hashCode: `${category.categoryName}${category.idCategory}`
      });
      this.montaObj(category.subCategories, appPage.item);
      appPage.count = appPage.item.length;
      arrayPage.push(appPage);
    }
  }

  async closeMenu(items: any) {
    console.log(items.length);
    if (items && items.length === 0) {
      await this.menu.toggle('mainMenu');
    }
  }

}
