import {
    Entity,
    PrimaryGeneratedColumn,
    Column,
    ManyToOne,
    JoinColumn,
    OneToMany
} from "typeorm";
import { Situation } from "../enums/situation.enum";

@Entity({ name: "category" })
export default class Category {
    @PrimaryGeneratedColumn()
    public idCategory: number;

    @Column()
    public categoryCode: string;

    @Column()
    public categoryName: string;

    @Column()
    public categoryIcon: string;

    @ManyToOne(() => Category, { nullable: true, cascade: true, onDelete: "CASCADE" })
    @JoinColumn({ name: "idCategoryOrigin", referencedColumnName: "idCategory" })
    public categoryOrigin: Category;

    @OneToMany(() => Category, type => type.categoryOrigin, { cascade: ["update", "insert"]})
    public subCategories: Category[];

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;
}
