import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {NoSessionGuard} from './guards/no-session.guard';
import {HasSessionGuard} from './guards/has-session.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'conta',
    canActivate: [HasSessionGuard],
    loadChildren: () => import('./pages/conta/conta.module').then(m => m.ContaPageModule)
  },
  {
    path: 'definicoes',
    loadChildren: () => import('./pages/definicoes/definicoes.module').then(m => m.DefinicoesPageModule)
  },
  {
    path: 'categoria',
    loadChildren: () => import('./pages/categoria/categoria.module').then(m => m.CategoriaPageModule)
  },
  {
    path: 'pagenotfound',
    loadChildren: () => import('./pages/pagenotfound/pagenotfound.module').then(m => m.PagenotfoundPageModule)
  },
  {
    path: 'notificacoes',
    canActivate: [NoSessionGuard],
    loadChildren: () => import('./pages/notificacoes/notificacoes.module').then(m => m.NotificacoesPageModule)
  },
  {
    path: 'p/:codigoProduto',
    loadChildren: () => import('./pages/produto/produto.module').then(m => m.ProdutoPageModule)
  },
  {
    path: 'contato',
    loadChildren: () => import('./pages/contato/contato.module').then(m => m.ContatoPageModule)
  },
  {
    path: 'pesquisa',
    loadChildren: () => import('./pages/pesquisa/pesquisa.module').then( m => m.PesquisaPageModule)
  },
  {
    path: 'favoritos',
    canActivate: [NoSessionGuard],
    loadChildren: () => import('./pages/favoritos/favoritos.module').then( m => m.FavoritosPageModule)
  },
  {
    path: 'carrinho',
    canActivate: [NoSessionGuard],
    loadChildren: () => import('./pages/carrinho/carrinho.module').then( m => m.CarrinhoPageModule)
  },
  {
    path: 'politica-privacidade',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'termos-servico',
    loadChildren: () => import('./pages/terms-of-service/terms-of-service.module').then( m => m.TermsOfServicePageModule)
  },
  {
    path: 'minhaconta',
    canActivate: [NoSessionGuard],
    loadChildren: () => import('./pages/minhaconta/minhaconta.module').then( m => m.MinhacontaPageModule)
  },
  {
    path: 'minhaconta/:pagina',
    canActivate: [NoSessionGuard],
    loadChildren: () => import('./pages/minhaconta/minhaconta.module').then( m => m.MinhacontaPageModule)
  },
  {
    path: 'pedido',
    loadChildren: () => import('./pages/pedido/pedido.module').then( m => m.PedidoPageModule)
  },
  {
    path: 'compras',
    loadChildren: () => import('./pages/compras/compras.module').then( m => m.ComprasPageModule)
  },
  {
    path: 'image-viewer',
    loadChildren: () => import('./components/image-viewer/image-viewer.module').then( m => m.ImageViewerPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  }

];

const routesFix: Routes = [
  { path: '**', redirectTo: '/pagenotfound' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes.concat(routesFix), { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
