import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import Address from '@shared/entities/address.entity';
import { WSResponse } from '@shared/entities/DTO/wsResponse.dto';

@Injectable({
  providedIn: 'root'
})
export class UserFavoriteService {

  constructor(
    private http: HttpClient
  ) { }

  public async getUserFavorite(): Promise<any> {
    return this.http.get(`/user/favorite/list`).toPromise();
  }

  public async deleteUserFavorite(sku): Promise<WSResponse> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.delete<WSResponse>(`/product/${sku}/unfavorite`, {headers: headers}).toPromise();
  }

  public async addUserFavorite(sku): Promise<WSResponse> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<WSResponse>(`/product/${sku}/favorite`, {headers: headers}).toPromise();
  }

}
