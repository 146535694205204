import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventHandler {
  private $events: Map<string, EventEmitter<any>> = new Map<string, EventEmitter<any>>();

  get events() {
    return this.$events;
  }

  public publish<T>(name: string, args: T): EventEmitter<T> {
    const emmiter = this.events.get(name);

    if (emmiter) {
      emmiter.emit(args);
      console.warn(`[Event Handler] Evento ${name} foi publicado!`);
      return emmiter;
    }

    console.warn(`[Event Handler] Evento ${name} não foi publicado ainda!`);
    return null;
  }

  public subscribe<T>(name: string, callback: (args: T) => any) {
    if (this.events.has(name)) {
      this.events.get(name).unsubscribe();
    } else {
      this.events.set(name, new EventEmitter<T>());
    }

    this.events.get(name).subscribe(callback);
  }

  public unsubscribe(name: string) {
    if (this.events.has(name)) {
      this.events.get(name).unsubscribe();
      this.events.delete(name);
    }
  }

  public unsubscribeAll() {
    this.events.forEach((value: EventEmitter<any>, key: string) => {
      value.unsubscribe();
    });
    this.events.clear();
  }
}
