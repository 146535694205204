import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BuscaCEPService {

  public proxy = environment.production ? '' : 'https://icity-cors.herokuapp.com/';
  public rastreioBaseURL = 'https://icity-cors.herokuapp.com/https://viacep.com.br/ws';

  constructor(
    private http: HttpClient
  ) { }

  public async fetch(cep: string): Promise<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const url = `${this.proxy}${this.rastreioBaseURL}/${cep}/json/`;
    // console.log(url);
    return this.http.get(url, {headers}).toPromise();
  }
}
