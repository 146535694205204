import {
    Entity,
    PrimaryGeneratedColumn,
    Column, Timestamp, CreateDateColumn
} from "typeorm";
import { UserSituation } from "../enums/userSituation.enum";
import {UserRank} from "../enums/userRank.enum";

@Entity({ name: "user" })
export default class User {
    @PrimaryGeneratedColumn()
    public idUser: number;

    @Column()
    public firstName: string;

    @Column()
    public lastName: string;

    @Column()
    public nickName: string;

    @Column({name: 'email'})
    public email: string;

    @Column({type: 'text'})
    public userCode: string;

    @Column()
    public password: string;

    @Column()
    public cpf: string;

    @Column()
    public dataNascimento: Date;

    @Column()
    public terms: boolean;

    @Column()
    public registerDate: Date;

    @Column({type: 'enum', enum: UserRank, default: UserRank.DEFAULT})
    public userRank: UserRank;

    @Column()
    public lastLoginDate: Date;

    @Column({ type: 'enum', enum: UserSituation, default: UserSituation.INACTIVE })
    public situation: UserSituation;

    public userFingerprint: string;

}
