import {
    Entity,
    PrimaryGeneratedColumn,
    Column, ManyToOne, JoinColumn, ManyToMany, JoinTable, AfterLoad, OneToMany
} from "typeorm";
import Cart from "./cart.entity";
import CartItemDiscounts from "./cartItemDiscount.entity";
import Complement from "./complement.entity";
import Offer from "./offer.entity";
import Product from "./product.entity";
import ProductPriceTablePrice from "./productPriceTablePrice.entity";
import ProductSchemeComplement from "./productSchemeComplement.entity";

@Entity()
export default class CartItem {
    @PrimaryGeneratedColumn()
    public idCartItem: number;

    @ManyToOne((type) => Product, { eager: true, nullable: false, onDelete: "CASCADE" })
    @JoinColumn({ name: "idProduct", referencedColumnName: "idProduct" })
    public product: Product;

    @ManyToMany(() => ProductSchemeComplement, complement => complement.idProductSchemeComplement, { eager: false, nullable: true })
    @JoinTable({
        name: 'cart_item_product_complement',
        joinColumn: {
            name: 'idCartItem',
            referencedColumnName: 'idCartItem'
        },
        inverseJoinColumn: {
            name: 'idProductSchemeComplement',
            referencedColumnName: 'idProductSchemeComplement'
        }
    })
    public productComplements: ProductSchemeComplement[];

    @Column({ name: "amount", type: "decimal", precision: 10, scale: 2, default: 0 })
    public amount: number;

    @ManyToOne((type) => ProductPriceTablePrice, { eager: false, nullable: false, onDelete: "CASCADE" })
    @JoinColumn({ name: "idProductPriceTablePrice", referencedColumnName: "idProductPriceTablePrice" })
    public price: ProductPriceTablePrice;

    @OneToMany(() => CartItemDiscounts, type => type.cartItem, { eager: true, cascade: true, onDelete: "CASCADE" })
    public itemDiscounts: CartItemDiscounts[];
    
    @Column({ name: "totalPrice", type: "decimal", precision: 10, scale: 2, default: 0 })
    public totalPrice: number;

    @ManyToOne((type) => Cart, { eager: false, nullable: false, onDelete: "CASCADE" })
    @JoinColumn({ name: "idCart", referencedColumnName: "idCart" })
    public cart: Cart;

    @AfterLoad()
    sortAttributes() {
        if (this?.productComplements?.length) {
            this.productComplements.sort((a, b) => a.sequence - b.sequence);
        }
    }

    get productPrice(): number {
        // Multitplica o valor do item pela quantidade do mesmo
        return (this?.price?.price ?? 0) * (this.amount ?? 0);
    }

    get productUnitPrice(): number {
        // Multitplica o valor do item pela quantidade do mesmo
        let value = this.productPrice;
        // Aplica desconto sobre desconto
        for (let offer of this.itemDiscounts.map(item => Object.assign(new Offer(), item.offer))) {
            value -= offer.offerDiscountPrice(value);
        }
        this.totalPrice = value;
        return value;
    }
}
