import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionService} from '../services/auth/session.service';
import {NavController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class HasSessionGuard implements CanActivate {

    constructor(private sessionManagerService: SessionService, private navCtrl: NavController) {
    }

  // tslint:disable-next-line:max-line-length
  // eslint-disable-next-line max-len
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return new Promise(async (resolve, reject) => {
            const session = await this.sessionManagerService.session;
            if (!session) {
               resolve(true);
            } else {
              resolve(false);
              // await this.navCtrl.navigateRoot('minhaconta');
              await window.history.back();
            }
        });
    }

}
