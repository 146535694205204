import { Situation } from "../enums/situation.enum";
import {
    Entity,
    PrimaryGeneratedColumn,
    Column
} from "typeorm";
import { OfferApplicationType } from "../enums/offerApplicationType.enum";

@Entity({ name: "offer" })
export default class Offer {
    @PrimaryGeneratedColumn()
    public idOffer: number;

    @Column({ name: "offerName" })
    public offerName: string;

    @Column({ name: "offerDescription", type: "text" })
    public offerDescription: string;

    @Column({ name: "discountPercentage", precision: 2})
    public discountPercentage: number;
    
    @Column({ name: "discountAmount", precision: 2})
    public discountAmount: number;

    @Column({ type: 'enum', enum: OfferApplicationType, default: OfferApplicationType.ITEM })
    public offerApplicationType: OfferApplicationType;

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;

    public offerDiscountValue(price: number) {
        if (this.isDiscountPercentage()) {
            const value = price - (price * (this.discountPercentage / 100));
            return value <= 0.00 ? 0.00 : value;
        } else {
            const value = price - this.discountAmount;
            return value <= 0.00 ? 0.00 : value;
        }
    }

    public offerDiscountPrice(price: number) {
        if (this.isDiscountPercentage()) {
            const value = (price * (this.discountPercentage / 100));
            return value <= 0.00 ? 0.00 : value;
        } else {
            const value = this.discountAmount;
            return value <= 0.00 ? 0.00 : value;
        }
    }

    public isDiscountPercentage() {
        if (this.discountPercentage && this.discountPercentage > 0.00) {
            return true;
        } else {
            return false;
        }
    }
}
