import * as moment from 'moment';

export const environment = {
  production: false,
  siteTitle: 'Might',
  siteLogo: 'assets/icon/Logos-8.png',
  apiBaseUrl: 'https://api.lojamight.com.br',
  description: 'Loja virtual',
  siteLogoSmall: '',
  siteFavicon: '',
  secret: '',
  instagramURL: 'https://www.instagram.com/mightnetworks/',
  twitterURL: 'https://twitter.com/MightNetworks',
  facebookURL: '',
  cookiesPolicy: moment('20220323').toDate(),
  isCookiesPolicyEnabled: false,
  termsOfService: moment('20220303').toDate(),
  sCepOrigem: '16200341',
  parcelasMaximas: 4,
  melhorEnvioBaseURL: 'https://sandbox.melhorenvio.com.br'
};
