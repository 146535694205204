import {
    Entity,
    PrimaryGeneratedColumn,
    Column, ManyToOne, JoinColumn
} from "typeorm";
import { Situation } from "../enums/situation.enum";
import Currency from "../entities/currency.entity";

@Entity({ name: "pricetable" })
export default class PriceTable {
    @PrimaryGeneratedColumn()
    public idPriceTable: number;

    @Column()
    public code: string;

    @Column({ type: "text" })
    public description: string;

    @ManyToOne((type) => Currency, { eager: true, nullable: true, onDelete: "SET NULL" })
    @JoinColumn({ name: "idCurrency", referencedColumnName: "idCurrency" })
    public currency: Currency;

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;
}
