import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';

export interface imageObj {
  src: string,
  title: string
};
@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.page.html',
  styleUrls: ['./image-viewer.page.scss'],
})
export class ImageViewerPage implements OnInit {

  public mountedSource: Array<imageObj>;
  public selectedImageInedx = 0;
  public isLoading = true;

  @Input() src: string;
  @Input() selectedSrc: string;
  @ViewChild(IonSlides) slides: IonSlides;

  constructor(private modalCtrl: ModalController) {

  }

  slidePrev() {
    this.slides.slidePrev();
  }
  slideNext() {
    this.slides.slideNext();
  }

  ngOnInit() {
    this.mountedSource = (JSON.parse(this.src) as Array<imageObj>);
  }

  ngAfterViewInit() {
    
    if (this.mountedSource && this.mountedSource.length >= 1) {
      this.compareAndGetIndex();
    }

  }

  close() {
    this.modalCtrl.dismiss();
  }

  compareAndGetIndex() {
    //alert(this.selectedSrc);
    this.selectedImageInedx = this.mountedSource.findIndex(item => item.src == this.selectedSrc);
    this.slides.slideTo(this.selectedImageInedx, 1).then( () => {
      setTimeout( ()=> {
        this.isLoading = false;
        this.slides.updateAutoHeight();
      }, 100);
    });
  }

  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

}
