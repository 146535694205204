import { Situation } from "../enums/situation.enum";
import {
    Entity,
    PrimaryGeneratedColumn,
    Column
} from "typeorm";

@Entity()
export default class PaymentMethod {
    @PrimaryGeneratedColumn()
    public idPaymentMethod: number;

    @Column({ name: "methodName" })
    public methodName: string;

    @Column({ name: "methodQuota", default: 1 })
    public methodQuota: number;

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;
}
