import { Situation } from "../enums/situation.enum";
import {
    Entity,
    PrimaryGeneratedColumn,
    Column,
    ManyToOne,
    JoinColumn,
    PrimaryColumn,
    ManyToMany,
    JoinTable
} from "typeorm";
import Product from "./product.entity";
import PaymentMethod from "./paymentMethod.entity";
import Complement from "./complement.entity";

@Entity()
export default class ProductPaymentMethod {
    @PrimaryGeneratedColumn()
    public idProductPaymentMethod: number;

    @ManyToOne((type) => Product, { eager: false, cascade: true, onDelete: "CASCADE" })
    @JoinColumn({ name: "idProduct", referencedColumnName: "idProduct" })
    public product: Product;

    @ManyToOne((type) => PaymentMethod, { eager: true, cascade: true, onDelete: "CASCADE" })
    @JoinColumn({ name: "idPaymentMethod", referencedColumnName: "idPaymentMethod" })
    public paymentMethod: PaymentMethod;

    @ManyToMany(() => Complement, complement => complement.idComplement, { eager: true, nullable: true })
    @JoinTable()
    public complements: Complement[];

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;
}
