import { Situation } from "../enums/situation.enum";
import { Column, Entity, PrimaryGeneratedColumn } from "typeorm";

@Entity()
export default class ProductComplementVariation {

    @PrimaryGeneratedColumn({ name: "idProductComplementVariation" })
    public idProductComplementVariation: number;

    @Column({ name: "variationName", unique: true })
    public VariationName: string;

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;
}
