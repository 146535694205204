import {
    Entity,
    PrimaryGeneratedColumn,
    ManyToOne, JoinColumn
} from "typeorm";
import CartItem from "./cartItem.entity";
import Offer from "./offer.entity";

@Entity()
export default class CartItemDiscounts {
    @PrimaryGeneratedColumn()
    public idCartItemDiscount: number;

    @ManyToOne((type) => Offer, { eager: true, nullable: false, onDelete: "CASCADE" })
    @JoinColumn({ name: "idOffer", referencedColumnName: "idOffer" })
    public offer: Offer;

    @ManyToOne((type) => CartItem, { eager: false, nullable: false, onDelete: "CASCADE" })
    @JoinColumn({ name: "idCartItem", referencedColumnName: "idCartItem" })
    public cartItem: CartItem;
}
