import {Component, ContentChild, OnInit} from '@angular/core';
import {IonInput} from '@ionic/angular';

@Component({
  selector: 'app-show-hide-pass',
  templateUrl: './show-hide-pass.component.html',
  styleUrls: ['./show-hide-pass.component.scss'],
})
export class ShowHidePassComponent implements OnInit {

  @ContentChild(IonInput) input: any;

  public showPassword = false;

  constructor() {}

  toggleShow() {
    this.showPassword = !this.showPassword;
    this.input.type = this.showPassword ? 'text' : 'password';
  }

  ngOnInit() {}

}
