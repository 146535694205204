import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WSResponse } from '@shared/entities/DTO/wsResponse.dto';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService {

  constructor(
    private http: HttpClient
  ) { }

  public async findProductInfoByCode(produtoCode: string): Promise<WSResponse> {
    return this.http.get<WSResponse>(`/product/${produtoCode}/info`).toPromise();
  }

  public async listProducts(category: string, body: any): Promise<WSResponse> {
    return this.http.post<WSResponse>(`/product/category/${category}/list`, body).toPromise();
  }

  public async searchProducts(body: any): Promise<WSResponse> {
    return this.http.post<WSResponse>(`/product/search/`, body).toPromise();
  }

  public async findProductDetailByCode(produtoCode: string): Promise<WSResponse> {
    return this.http.get<WSResponse>(`/product/${produtoCode}/detail`).toPromise();
  }
}
