import {
    Entity,
    PrimaryGeneratedColumn,
    Column, ManyToMany, JoinTable, OneToMany, AfterLoad
} from "typeorm";
import { Situation } from "../enums/situation.enum";
import Category from "./category.entity";
import ProductPriceTablePrice from "./productPriceTablePrice.entity";
import ProductScheme from "./productScheme.entity";
import ProductAttributes from "./productAttribute.entity";
import ProductPaymentMethod from "./productPaymentMethod.entity";

@Entity({
    name: "product"
})
export default class Product {
    @PrimaryGeneratedColumn()
    public idProduct: number;

    @Column()
    public name: string;

    @Column()
    public alias: string;

    @Column({ type: "text" })
    public description: string;

    @Column({ type: 'text' })
    public sku: string;

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;

    @ManyToMany(() => Category, category => category.idCategory, { eager: true, onDelete: "CASCADE" })
    @JoinTable({
        name: 'product_category',
        joinColumn: {
            name: 'idProduct',
            referencedColumnName: 'idProduct'
        },
        inverseJoinColumn: {
            name: 'idCategory',
            referencedColumnName: 'idCategory'
        }
    })
    public categories: Category[];

    @OneToMany(() => ProductPriceTablePrice, pptp => pptp.product, {
        eager: true
    })
    public prices: ProductPriceTablePrice[];

    @OneToMany(() => ProductScheme, ps => ps.product, {
        eager: true
    })
    public productSchemes: ProductScheme[];

    @OneToMany(() => ProductAttributes, pa => pa.product, {
        eager: true
    })
    public productAttributes: ProductAttributes[];

    @OneToMany(() => ProductPaymentMethod, ppm => ppm.product, {
        eager: true
    })
    public paymentMethods: ProductPaymentMethod[];

    public favorito: boolean = false;

    @AfterLoad()
    sortAttributes() {
        if (this?.productSchemes?.length) {
            this.productSchemes.sort((a, b) => a.sequence - b.sequence);
        }
        if (this?.productAttributes?.length) {
            this.productAttributes.sort((a, b) => a.sequence - b.sequence);
        }
        if (this?.paymentMethods?.length) {
            this.paymentMethods.sort((a, b) => b.paymentMethod.methodQuota - a.paymentMethod.methodQuota);
        }
    }

    public productImages() {
        let pictures = [];
        this.productSchemes.forEach(schemes => {
            schemes.productSchemeComplements.forEach(schemesComplement => {
                if (schemesComplement.image.length >= 3) {
                    if (schemesComplement.image.split(';').length > 0) {
                        for (const img of schemesComplement.image.split(';')) {
                            pictures.push(img);
                        }
                    } else {
                        pictures.push(schemesComplement.image);
                    }
                }
            })
        });
        return pictures;
    }
}
