import {
    Entity,
    PrimaryGeneratedColumn,
    Column
} from "typeorm";
import {Situation} from "../enums/situation.enum";

@Entity({ name: "complement" })
export default class Complement {
    @PrimaryGeneratedColumn()
    public idComplement: number;

    @Column()
    public code: string;

    @Column({ type: "text"})
    public description: string;

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;
}
