import { Situation } from "../enums/situation.enum";
import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from "typeorm";
import PriceTable from "./priceTable.entity";
import Product from "./product.entity";

@Entity()
export default class ProductPriceTablePrice {

    @PrimaryGeneratedColumn({ name: "idProductPriceTablePrice" })
    public idProductPriceTablePrice: number;

    @ManyToOne((type) => Product, { eager: false, cascade: true, onDelete: "CASCADE" })
    @JoinColumn({ name: "idProduct", referencedColumnName: "idProduct" })
    public product: Product;

    @ManyToOne((type) => PriceTable, { eager: true, cascade: true })
    @JoinColumn({ name: "idPriceTable", referencedColumnName: "idPriceTable" })
    public priceTable: PriceTable;

    @Column({ type: 'enum', enum: Situation, default: Situation.ACTIVE })
    public situation: Situation;

    @Column({ name: "price", type: "decimal", precision: 10, scale: 2, default: 0 })
    public price: number;
}
